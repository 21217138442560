// extracted by mini-css-extract-plugin
export var footer = "Mc";
export var footerBottom = "Tc";
export var footerBottom__copyWrapper = "Xc";
export var footerBottom__footerLink = "Wc";
export var footerBottom__footerSocial = "Zc";
export var footerBottom__left = "Uc";
export var footerBottom__links = "Vc";
export var footerBottom__textDeveloper = "Yc";
export var footerTop = "Oc";
export var footerTop__appButton = "Rc";
export var footerTop__logo = "Sc";
export var footerTop__menu = "Qc";
export var footerWrapper = "Nc";
export var withButton = "Pc";